import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const UsecaseImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      UC0001: file(relativePath: { eq: "usecases/UC0001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002: file(relativePath: { eq: "usecases/UC0002.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0003: file(relativePath: { eq: "usecases/UC0003.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0004: file(relativePath: { eq: "usecases/UC0004.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0005: file(relativePath: { eq: "usecases/UC0005.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0001_ss_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0001_ss_imgs1_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0001_ss_imgs1_img1: file(
        relativePath: { eq: "usecases/UC0001_ss_imgs1_img1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0001_ss_imgs1_img2: file(
        relativePath: { eq: "usecases/UC0001_ss_imgs1_img2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0001_ss_imgs1_img3: file(
        relativePath: { eq: "usecases/UC0001_ss_imgs1_img3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0001_ss_imgs2_img0: file(
        relativePath: { eq: "usecases/UC0001_ss_imgs2_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1412) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002_ss_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0002_ss_imgs1_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002_ss_imgs1_img1: file(
        relativePath: { eq: "usecases/UC0002_ss_imgs1_img1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002_ss_imgs1_img2: file(
        relativePath: { eq: "usecases/UC0002_ss_imgs1_img2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002_ss_imgs1_img3: file(
        relativePath: { eq: "usecases/UC0002_ss_imgs1_img3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0002_ss_imgs1_img4: file(
        relativePath: { eq: "usecases/UC0002_ss_imgs1_img4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0003_ss_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0003_ss_imgs1_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0003_ss_imgs1_img1: file(
        relativePath: { eq: "usecases/UC0003_ss_imgs1_img1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0003_tr_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0003_tr_imgs1_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0003_tr_imgs1_img1: file(
        relativePath: { eq: "usecases/UC0003_tr_imgs1_img1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0004_ss_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0004_ss_imgs1_img0.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UC0005_ss_imgs1_img0: file(
        relativePath: { eq: "usecases/UC0005_ss_imgs1_img0.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || "Usecase Image"}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default UsecaseImg
