import SnacksMakerUseCase from "../docs/SnacksMakerUseCase.pdf"
import VehicleManufacturerUseCase from "../docs/VehicleManufacturerUseCase.pdf"
import OleochemicalsSpecialistsUseCase from "../docs/OleochemicalsSpecialistsUseCase.pdf"
import PetroleumRetailUseCase from "../docs/PetroleumRetailUseCase.pdf"
import MallUseCase from "../docs/MallUseCase.pdf"

const usecases = [
  {
    key: "UC0001",
    head:
      "Integration Wizards brews with the second largest snacks maker of the world",
    desc:
      "An American multinational confectionery, food & beverage holding company with presence in over 150 countries. With a revenue of over $26 Billion and employees over 83,000 individuals.",
    chl: `
        The customer is a large distribution network for its diverse range of products, globally. The products reach the customers from the manufacturing facility through a network of warehouses and logistics operations involving multiple stake holders.

        They are directly involved in warehouse operations and needed to measure the volume throughput and reduce the cost per CBM. They were looking for a solution that could help them understand their warehouse operations and monitor KPI associated with volume utilisation and cost reduction.
    `,
    ssn: {
      desc1: `
            The current process of monitoring KPIs such as CBM, TAT, Asset Utilisation, Person Utilisation, and safety compliance was manual and, therefore, inaccurate. To understand and improve their warehouse operation, efficiency, and safety, we proposed the use of our AI platform- IRIS.

            IRIS Computer Vision was plugged into existing CCTV cameras to monitor custom scenarios based on the customer’s requirements and provide detailed analytics in real-time. For them, IRIS Net was customised to detect and monitor:
        `,
      points1: [
        "CBM",
        "Dock Utilisation",
        "Truck TAT",
        "Forklift Utilisation",
        "Personnel Utilisation",
        "Safety Compliance",
        "Near-misses",
      ],
      imgs1: ["img0", "img1", "img2", "img3"],
      desc2: `
            Furthermore, the dashboard was customised to provide daily operational analytics. The KPIs were monitored for different shifts, individual docks, and different types of vehicles.
        `,
      imgs2: ["img0"],
    },
    trf: {
      desc1: `
            They now have a complete track of their warehouse and monitors operational efficiency accurately in real-time using IRIS. Dashboard provides high level details which can be drilled down to individual assets, trucks and docks utilisation metrics. This is helping them monitor and improve their operational efficiency, analyse workforce utilisation and take business decisions on the go.
        `,
    },
    comp: "Mondelez",
    title: "Snacks Maker",
    doc: SnacksMakerUseCase,
  },
  {
    key: "UC0002",
    head: "Integration Wizards drives innovation in yard management",
    desc:
      "Amongst those Indian companies that have gained global recognition in the automobile industry. Being the fourth largest two and three-wheeler manufacturer in the world, a significant share of its revenue comes from exporting vehicles to more than 70 countries. In 2018 alone, sales of more than 3.2 Million two-wheelers and about 600,000 commercial vehicles were recorded, highlighting a 30% increase since 2017. Interestingly, about 3.8 Million vehicles are manufactured across three manufacturing plants.",
    chl: `
        They tapped into Integration Wizards to identify the bottlenecks in their yard management and outbound logistics. The company has thousands of assets that need coordination to function efficiently and effectively. One of the key pain-points was locating individual ‘vehicle identification number’ (VIN) as well as recording the FIFO for the movement of 8000 bikes from the assembly line to the yard and from there for further dispatch. They aimed to improve process efficiency as well.

        Taking a closer look at their existing process, three stages of vehicle movement was identified: (i) from Pre-Delivery Inspection (PDI) to yard, (ii) Relocating within the yard, (iii) and Yard to dispatch.

        From assembly line the vehicles go for a PDI, after which VIN is entered in SAP. However, there was no tracking system from either PDI to the yard or from the yard to dispatch.

        Most of the mapping of the vehicles was done manually and usually memorised by the more experienced and skilled employees. The bikes riders had to match the VIN with the bike. However, a major time got invested in locating individual bikes in the yard for relocating or dispatch, resulting in an increase in the overall TAT for outbound orders.
    `,
    ssn: {
      desc1: `
            To streamline this process, Integration Wizards intended to solve this challenge with technology and innovation. The task was to present a solution that was easy to use for the riders, increased their efficiency of yard operations, and deliver a cost-effective solution.

            Furthermore, this solution had to help with optimisation of parking lot assignment to reduce the effort and operational cost for vehicle movement in the yard (from PDI to dispatch), make this information available at the click of a button, and have a map and routing feature for the riders to reach the vehicles efficiently.

            Now, the easiest would have been to use GPS. However, most of the yard was indoor and GPS is inaccurate indoors, thus rendering the need for an indoor tracking technology.

            Further, the precision required for tracking bikes had to be less than one meter. To tackle this, Integration Wizards Solutions leveraged Ultra-Wide Band (UWB) technology on its platform IRIS (Based on MS Azure). The UWB technology works on “time of flight” for positioning and gives a precision of up to 30 centimetres.

            Taking an innovative approach and instead of tracking bikes which are driven or moved manually, we decided to track the riders using the UWB technology to an accuracy of 30 centimetres. This also reduced the cost of deployment significantly as we were tracking 25-30 riders instead of 8000 bikes.

            An application was developed to work with UWB beacons and track the real-time location of each rider, with the features to scan the VIN for a bike, get details for its movement in the yard, get navigation on an indoor map and real-time insight on all the bikes in the yard.

            A high-level overview to the application flow is given below:
        `,
      points1: [
        "Real-time Tracking",
        "Real-time visibility of all the bike in the yard",
        "Seamless Integration with backend system",
        "Yard Navigation",
        "Ease of use (Quick training for a new employee)",
      ],
      imgs1: ["img0", "img1", "img2", "img3", "img4"],
      desc2: `
            The entire solution is powered by Azure IoT Hub.
        `,
      points2: [
        {
          text: "Highlights:",
          subPoints: [
            "Riders scan the bikes for VIN at PDI or in the yard, they get details of vehicle movement",
            "A map with an optimised route opens for navigation",
            "Rider parks the bike at the assigned lot and ends trip",
          ],
        },
      ],
    },
    trf: {
      desc1: `
            A real-time tracking solution for yard management which gives them real-time visibility of all the bikes at any given time. This is helping them reduce the ageing cost of vehicles, optimise vehicle storage, and remove dependability on employees for tracking.

            Key performance indicators after the tracking solution implementation includes:
        `,
      points1: [
        "FIFO implementation",
        "Reduced dispatch time",
        "Optimised yard operations cost",
        "Improved rider throughput",
      ],
    },
    comp: "Bajaj",
    title: "Vehicle Manufacturer",
    doc: VehicleManufacturerUseCase,
  },
  {
    key: "UC0003",
    head: "Integration Wizards spots a spark",
    desc: `
        One of India’s leading oleochemicals specialists that manufactures and markets over 100 chemicals to more than 80 countries.

        Spread across 140 acres with a patronage of 1.1 billion global consumers today and has a production capacity of over 2,73,700 metric tons of chemical products annually.
    `,
    chl: `
        As they say, one incident can bring about a serious change.

        In 2015, a massive fire consumed ₹2.5 Crores worth assets in the Plant. As the factory was autonomous and the fire started in the thermic fluid boiler at the end of the plant, it easily went unnoticed for a few hours until it had grown extensively.

        Being a chemical factory, it had many flammable chemicals that added fuel to the fire. This raised serious concerns and challenges for industrial safety and compliance in manufacturing plants.

        Therefore, they were looking for an effective solution to improve its process-safeguarding practices and also lower the number industrial accidents as much as possible.

        Traditionally, fire detection devices or smoke detectors are connected individually to conventional fire alarm panels using wires. If the device detected smoke or heat, it gave a signal to the control panel and set off the alarm. While this setting worked for a small enclosed space such as office building, in large spaces such as factories, warehouses, etc. these devices rendered inefficient as they require sufficient levels of smoke build-up to turn them on. Thus, the fire spreads already, causing much damage by the time an alarm is raised. This is why a computer vision-based fast responsive solution was more viable.
    `,
    ssn: {
      desc1: `
            Integration Wizards AI-Powered Computer Vision Solution, IRIS can detect and pinpoint the location of the fire as soon as it starts. This allows it to be extinguished way before it gets out of control.

            IRIS utilises the existing CCTV network and integrates them with applications and systems. It can detect a fire within 10-15 seconds and from a distance of 50 meters. Since it’s compatible with different devices, it also reduces validation and testing costs. Moreover, it's scalable to cover the entire plant and cost-effective.

            Such a solution when implemented across the manufacturing plant, reduces the industrial accidents vulnerability to employees and assets, while also improving regulatory compliance for industrial safety.
        `,
      imgs1: ["img0", "img1"],
      desc2: `
            In this case more than 200 CCTV cameras are in operation with IRIS. It provides real-time fire detection with a snapshot of the area where it identifies fire. This image is then displayed on the screen placed in the fire control room with a hooter sound.

            Our machine learning models operate both at the edge and in the cloud. The model can identify interest at the edge in near time and pass this information to MS Azure cloud to process and raise real-time alarms.

            Azure cloud powers this transformation in sync with IRIS and combines them with many open-source platforms such as TensorFlow, Kafka, etc. to provide a quick output.,

            Furthermore, deep learning utilises CCTV footage to train its algorithms and continuously improve itself.
        `,
    },
    trf: {
      desc1: `
            Operational integration allowed to have a seamless process and interface while maintaining a safe work environment. Furthermore, from an operational perspective, it makes no difference where the application is running as long as all the required information is available to the fire control room. Therefore, the entire premises is monitored 24/7 as well as equipped to prevent or combat any fire accidents.

            A truly integrated IRIS safety solution delivers:
        `,
      points1: [
        "15 seconds to detect fire vs 3-5 minutes on traditional fire sensors",
        "Real-time alarm on Email/Portal/Fire control room",
        "Integration with local hooter/monitor at the fire control room",
        "SMS and Push notification",
        "Realtime dashboard for alarms",
      ],
      imgs1: ["img0", "img1"],
    },
    comp: "Godrej",
    title: "Oleochemicals Specialists",
    doc: OleochemicalsSpecialistsUseCase,
  },
  {
    key: "UC0004",
    head: "Integration Wizards transforms Petroleum Retail",
    desc: `
        Petroleum retail industry has approx. 30 million customers daily, across the globe. In India, five major companies have the largest Oil & Gas market share. Thus, in such a competitive environment, it is essential to understand customers to improve services.

        One important aspect is ‘safety’ and fuel stations are highly prone to safety risks ranging from accidents, theft, hit & run, smoking, and fire among others.
    `,
    chl: `
        The petroleum retail outlets were looking to detect SOP Compliance, measure efficiency, identify safety risks, waiting time, fuelling time, customer demographics, repeat customer, and vehicle demographics.

        Moreover, they wanted this data in real-time and needed an automated solution that could analyse the long hours of CCTV footages via multiple cameras as this is almost impossible manually.
    `,
    ssn: {
      desc1: `
            Integration Wizards AI-Powered Computer Vision Solution, IRIS can analyse footages from multiple cameras and derive meaningful insights.

            Powered by Azure cloud, in combination with open source platforms such as TensorFlow and Kafka, IRIS is trained on machine learning models that operate both at the edge and in the cloud. It can identify interest at the edge in real-time, process it, and use the cloud provider for alerts.,

            From filtering the footage in real-time and finding events that need attention, it also uses the same data to train and continuously improve.

            Thus, instead of receiving a snapshot after an event, it is a 24/7 ongoing real-time data feed that helps retail outlets conduct their operations far more safely.
        `,
      points1: [
        {
          text: "SOP Compliance",
          subPoints: [
            "DSM’s presence in the forecourt",
            "Decantation process and PPE compliance",
            "DSM’s presence at Air Tower",
            "Lux compliance",
          ],
        },
        {
          text: "Efficiency",
          subPoints: [
            "Vehicle Filling-time and Peak-time by vehicle type",
            "Vehicle Queue time",
            "Air tower wait time and filling time",
            "Decantation time taken",
          ],
        },
        {
          text: "Customer Profile and Loyalty",
          subPoints: [
            "Records number plates of all vehicles coming in",
            "Customer demographics (Age, Gender)",
            "Loyal customers by vehicle type",
          ],
        },
        {
          text: "Alarms",
          subPoints: [
            "Alarms for SOP violations around DSM presence at forecourt, air tower, Lux",
            "Alarm for safety concern that include smoking, fire, crowd, etc.",
            "Alarms around camera tampering, covering or malfunction of cameras",
          ],
        },
        {
          text: "Default Dashboard",
        },
      ],
      imgs1: ["img0"],
    },
    trf: {
      desc1: `
            A truly integrated IRIS analytics solution delivers:
        `,
      points1: [
        "Lost Revenue Calculation",
        "Score Calculation – Loyalty, Compliance, Decantation & Efficiency",
        "Average Wait time, Peak time and Fuelling time calculation in real time",
        "Real-time alarm on Email/Portal/Mobile for SOP violations",
        "Loyal Customers, Demographics and LUX monitoring",
        "Realtime dashboard for alarms",
      ],
    },
    comp: "Reliance",
    title: "Petroleum Retail",
    doc: PetroleumRetailUseCase,
  },
  {
    key: "UC0005",
    head: "Integration Wizards helps malls reopen and fight COVID-19",
    desc: `
        One of the biggest mall chains in India deploys IRIS across 8 cities to reopen safely during COVID19.
    `,
    chl: `
        The customer wanted to reopen operations safely during covid19. The goal was to ensure limited entry and facemask usage by all visitors to the malls.

        The key challenge was to keep a real-time track of the person density inside the mall premises and ensure the staff strictly adheres to the person count limit established to ensure safety.
    `,
    ssn: {
      desc1: `
            The malls had an existing CCTV infrastructure for surveillance and security. These were being manually monitored and were limited capture and store capabilities.

            IRIS was plugged into the existing CCTV camera to transform them into smart cameras and process the video footage in real-time.

            Key areas identified for footfall analytics were,
        `,
      points1: ["Mall common areas", "Food court", "Entry/Exit gates"],
      imgs1: ["img0"],
    },
    trf: {
      desc1: `
            With IRIS they have a complete track of the mall premises and get real-time data on the footfall at different locations inside the mall. The solution is deployed across 8 cities in India.

            The dashboard provides data on each entry/exit point, different mall areas and the food court, which is helping them regulate the entry of customers in different areas based on the person density to ensure safety.
        `,
    },
    comp: "Phoenix",
    title: "Mall",
    doc: MallUseCase,
  },
]

export const getUsecases = () => usecases
