import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/usecases.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { setDefaultHeaderTheme, navbarUpdate } from "../utils/common-util"
import { getUsecases } from "../data/usecases"
import UsecaseImg from "../components/usecase-img"

class UsecasesPage extends Component {
  constructor(props) {
    super(props)

    this.usecases = getUsecases()
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate("nav-about")
  }

  render() {
    return (
      <Layout>
        <SEO title="Usecases" />
        <div className="ucs-top-contents iww-full-height">
          <div id="stars1" className="stars"></div>
          <div id="stars2" className="stars"></div>
          <div id="stars3" className="stars"></div>

          <div className="ucs-heading">
            <span>Use cases</span>
            <span className="right-line"></span>
          </div>
          <div className="ucs-cards">
            {this.usecases.map((uc, i) => {
              return (
                <AniLink
                  key={uc.key}
                  className="ucs-card"
                  to={"/usecase?case=" + i}
                >
                  <UsecaseImg name={uc.key}></UsecaseImg>
                  <div className="card-title">
                    <span>{uc.head}</span>
                    <button className="button">
                      <span className="icon">
                        <i className="fas fa-external-link-alt fa-lg"></i>
                      </span>
                    </button>
                  </div>
                </AniLink>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default UsecasesPage
